<template>
  <div v-if="asset" class="ml-2 mr-1">
      <b-col>
        <b-form-group>
          <template #label><span class="text-danger">*</span> 资产名称：</template>
          <b-form-input v-model="asset.name" placeholder="请输入名称" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <template #label><span class="text-danger">*</span> 类型：</template>
          <b-form-input v-model="asset.type" placeholder="请输入类型" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <template #label><span class="text-danger">*</span> 重要程度：</template>
          <b-form-input v-model="asset.level" placeholder="请输入重要程度" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <template #label><span class="text-danger">*</span> IP地址：</template>
          <b-form-input v-model="asset.ipAddress" placeholder="请输入IP地址" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <template #label><span class="text-danger">*</span> 端口：</template>
          <b-form-input v-model="asset.port" placeholder="请输入端口" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <template #label><span class="text-danger">*</span> 备注：</template>
          <b-form-input v-model="asset.note" placeholder="请输入备注" />
        </b-form-group>
      </b-col>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckboxGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BLink,
  BProgress,
} from "bootstrap-vue";

import { mapState } from "vuex";

export default {
  name: "AssetEdit",

  props: ["obj", "toSave"],

  components: {
    BCard,
    BCol,
    BFormGroup,
    BFormCheckboxGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BRow,
    BLink,
    BProgress,
  },

  data() {
    return {
      asset: null,

      aryCompliance: [],
      attachmentArray: [],
      htmlContent: null,
      toPDFProgress: 0,

      quillEditorOption: { theme: "bubble", placeholder: "在这里输入内容" },
    };
  },

  computed: {
    ...mapState({
     
    }),
  },

  mounted() {
    this.cloneObject();
  },

  watch: {
    obj: {
      handler(val) {
        this.cloneObject();
      },
      deep: true,
    },

    toSave: {
      handler(val) {
        console.log("toSave", val);
        if (val) {
          // debugger
          this.$emit("save", this.asset);
        }
      },
      deep: true,
    },
  },

  methods: {
    cloneObject() {
      this.asset = JSON.parse(JSON.stringify(this.obj));
    },

    loadFileContent() {

    },
    
  },
};
</script>

<style>
</style>