<template>
    <div>
        <b-row class="match-height">
            <b-col xl="2" md="4" sm="6">
                <statistic-card-vertical color="danger" icon="CpuIcon" statistic="50" statistic-title="总资产数" />
            </b-col>
            <b-col xl="2" md="4" sm="6">
                <statistic-card-vertical icon="MapIcon" statistic="10" statistic-title="服务器数量" color="info" />
            </b-col>
            <b-col xl="2" md="4" sm="6">
                <statistic-card-vertical icon="BookIcon" statistic="30" statistic-title="终端数量" />
            </b-col>
            <b-col xl="2" md="4" sm="6">
                <statistic-card-vertical color="warning" icon="MessageSquareIcon" statistic="12" statistic-title="数据库数量" />
            </b-col>
            <b-col xl="2" md="4" sm="6">
                <statistic-card-vertical color="danger" icon="UserCheckIcon" statistic="3" statistic-title="其他" />
            </b-col>
        </b-row>
        <b-card>
            <!-- search & new -->
            <b-row>
                <b-col cols="3">
                    <b-form-select placeholder="数据分类" v-model="query_catalog" :options="catalogOptions" />
                </b-col>
                <b-col cols="3">
                    <b-form-select placeholder="数据分级" v-model="query_level" :options="levelOptions" />
                </b-col>
                <b-col cols="3">
                    <b-input-group>
                        <b-form-input type="text" v-model="query_keyword" placeholder="名称、描述" @keypress.enter="doSearch" />
                        <b-input-group-append is-text>
                            <feather-icon icon="SearchIcon" class="cursor-pointer" @click="doSearch" />
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col cols="3" class="text-right">
                    <b-button variant="primary" @click="doSearch">
                        <feather-icon icon="RefreshIcon" class="mr-50" />
                        <span class="align-middle">刷新</span>
                    </b-button>
                    <b-button variant="primary" @click="showEditDlg()" class="ml-1">
                        <feather-icon class="mr-50" icon="PlusIcon" />
                        <span class="align-middle">导入</span>
                    </b-button>
                    <b-button variant="primary" @click="showEditDlg()" class="ml-1">
                        <feather-icon class="mr-50" icon="PlusIcon" />
                        <span class="align-middle">新增</span>
                    </b-button>
                </b-col>
            </b-row>
            <!-- list -->
            <b-row>
                <!--data list--->
                <b-col cols="12">
                    <b-table ref="listTable" :items="listTableItems" :fields="listTableFields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" striped hover responsive :per-page="listTablePerPage" :current-page="listTableCurrentPage" class="mt-1" show-empty empty-text="没有找到符合要求的数据" @row-clicked="showEditDlg">
                        <template #cell(manager)="data">
                            <b-media vertical-align="center">
                                <template #aside>
                                    <b-avatar size="32" :src="data.item.manager.avatar" :text="data.item.manager.name" variant="light-primary" />
                                </template>
                                <span class="font-weight-bold d-block text-nowrap">
                                    {{ data.item.manager.name }}
                                </span>
                                <small class="text-muted">{{ data.item.manager.email }}</small>
                            </b-media>
                        </template>
                        <template #cell(actions)="data">
                            <div class="text-right text-nowrap">
                                <b-button variant="flat-success" class="btn-icon" @click="viewItem(data)">
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-col>
                <!--pagination--->
                <b-col cols="12">
                    <b-pagination v-model="listTableCurrentPage" :total-rows="listTableTotalRows" :per-page="listTablePerPage" align="center" size="sm" class="my-0" />
                </b-col>
            </b-row>
            <!-- edit -->
            <AssetEdit :item="currentItem" :visible="showEdit" @hidden="onEditHidden"/>
        </b-card>
        <!-- edit -->
        <b-sidebar id="sidebar-edit" bg-variant="white" width="35%" right backdrop shadow :title="currentItem.name" v-if="currentItem" v-model="showEdit">
            <asset-edit :obj="currentItem" :toSave="toSave" @close="showEdit = false" @save="onSave" />
            <template #footer>
                <div class="p-2 border-top text-right">
                    <b-button variant="primary" class="mr-1" @click="toSave = true">保存</b-button>
                    <b-button variant="secondary" @click="showEdit = false;toSave = false;">取消</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>
<script>
import {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    BMedia,
    BSidebar,
    VBToggle,
} from "bootstrap-vue";

import AssetView from "./AssetView.vue";
import AssetEdit from "./AssetEdit.vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";


export default {
  name: "DataMapAsset",
    components: {
        BRow,
        BCol,
        BCard,
        BCardText,
        BFormSelect,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BTable,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInvalidFeedback,
        BAvatar,
        BFormCheckbox,
        BMedia,
        StatisticCardVertical,
        BSidebar,
        VBToggle,
        AssetEdit,
        AssetView,
    },

    data() {
        return {
            queryData: null,
            // select options
            catalogOptions: [
                { value: "", text: "资产类型" },
                "终端PC",
                "服务器",
                "数据库",
                "业务系统",
                "网络设备",
                "安全设备",
                "其他",
            ],
            levelOptions: [
                { value: "", text: "资产分级" },
                "关键资产",
                "重点资产",
                "一般资产",
                "其他",
            ],

            // query
            query_catalog: "",
            query_level: "",
            query_compliance: "",
            query_keyword: "",

            // list table
            sortBy: "index",
            sortDesc: false,
            listTableFields: [
                { key: "id", label: "序号" },
                { key: "name", label: "名称" },
                { key: "type", label: "类型" },
                { key: "level", label: "分级" },
                { key: "ipAddress", label: "IP地址" },
                { key: "port", label: "端口" },
                { key: "note", label: "备注" },
                { key: "actions", label: "操作" },
            ],
            listTablePerPage: 10,
            listTablePageOptions: [10, 15, 30],
            listTableTotalRows: 10,
            listTableCurrentPage: 1,

            // edit
            currentItem: null,
            baseInfoVisible: true,
            adminUser: null,
            showEdit: false,

            selected: [],
      
            assetNew: {
              id: 0,
              eid: 0,
              name: "新建资产",
              type: "",
              level: "",
              ipAddress: "",
              port: "",
              note: "",
              status: 1,
            },
            complianceArray: [], // [] <=>","
            attachmentArray: [], // [] <=>","

            toSave: false,
      };
    },

    computed: {
        listTableItems() {
            return this.queryData ? this.queryData : [];
        },
    },

    mounted() {
        this.doSearch();
    },

    methods: {
        doSearch() {
            // debugger
            this.$http
                .get("/datamapassets").then((res) => {
                    this.queryData = res.data.data.list;
                    this.listTableTotalRows = res.data.data.total;
                });
        },

        onEditHidden() {
            this.showEdit = false;
        },

        //
        editItem(data) {
          this.currentItem = JSON.parse(JSON.stringify(data));
          this.showEdit = true;
        },

        preview(item) {
          this.$router.push(`/datamapassets/${item.id}`);
          //this.viewItem = item;
          //this.showView = true;
        },
        showEditDlg(item) {
          this.currentItem = item ? item : this.assetNew;
          this.showEdit = true;
        },
        onSave(newObj) {
          if (newObj.id) {
            this.$http
              .put("/datamapsassets/" + newObj.id, newObj)
              .then((res) => {
                this.doSearch();
                this.showEdit = false;
              });
          } else {
            this.$http.post("/datamapassets", newObj).then((res) => {
              this.doSearch();
              this.showEdit = false;
            });
          }
        },

        successed(msg) {
          this.$swal({
            title: "成功",
            text: msg,
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "确认",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              // this.backToList();
            }
          });
        },

        onEditHidden() {
          this.showEdit = false;
        },
    },   
};
</script>