<template>
  <div v-if="asset" class="ml-2 mr-1">
   

    <b-col class="my-1"> 资产名称：{{ asset.name }} </b-col>

    <b-col class="my-1"> 资产类型：{{ asset.type }} </b-col>

    <b-col class="my-1"> 重要程度：{{ asset.level }} </b-col>

    <b-col class="my-1"> IP地址：{{ asset.ipAddress }} </b-col>

    <b-col class="my-1"> 端口：{{ asset.port }} </b-col>

    <b-col class="my-1"> 备注：{{ asset.note }} </b-col>

    
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BIconShieldLock,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckboxGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BLink,
  BProgress,
} from "bootstrap-vue";

import pdf from "vue-pdf";

export default {
  name: "AssetView",

  components: {
    BBadge,
    BCard,
    BIconShieldLock,
    BCol,
    BFormGroup,
    BFormCheckboxGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BRow,
    BLink,
    BProgress,
    pdf,
  },

  data() {
    return {
      asset: null,
      aryCompliance: [],

      numPages: 0,
      loadingPdfTask: null,
    };
  },

  beforeCreate() {
    let { id } = this.$route.params;
    this.$http.get("/datamapassets/" + id).then((res) => {
      this.asset = res.data.data;
    });
  },

  computed: {},

  methods: {},
};
</script>

<style>
</style>